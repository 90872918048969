import React from 'react'

import HeaderButton from './HeaderButton'
import { navigate } from '@reach/router'

/**
 * Component: HeaderDisplayStock
 * Header cart icon that links to current cart.
 */
const HeaderHelp = React.memo(({ ...restProps }) => {

  const handleHelpButton = () => {
    navigate('ayuda');
  }

  return (
    <HeaderButton
      icon="question"
      title="Ayuda, consulta las preguntas más habituales"
      id="display-help-button"
      onClick={handleHelpButton}
      {...restProps}
    />
  )
})

export default HeaderHelp
